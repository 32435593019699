<template lang="pug">
.main-wrapper.checkup-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Checkup</b>

        Dialog(header='Remover checkup' :visible.sync='dialogApagar' :modal='true')
            p Deseja remover o checkup selecionado?
            .ta-center.my-4
                Button.p-button-danger(label='Remover' @click='remove()')

        Dialog.dialog-visualizarProcedimentos(header='Visualizar procedimentos' :visible.sync='dialogVisualizar' :modal='true')
            .waitingProcedimentos(v-if='waitingProcedimentos')
                ProgressSpinner(strokeWidth='4')
            div(v-else)
                p.text-title {{ dialogVisualizar_data.ds_checkup }}
                div(v-for='procedimento, idx in dialogVisualizar_data.cd_procedimentos' :key='idx')
                    p.text-procedimento • {{ procedimento.ds_procedimento }}

        Dialog.dialog-visualizarAgendas(header='Visualizar agendas' :visible.sync='dialogVisualizarAgendas' :modal='true')
            .waitingAgendas(v-if='waitingAgendas')
                ProgressSpinner(strokeWidth='4')
            div(v-else)
                div(v-for='agenda, idx in dialogVisualizarAgendas_data.agendas' :key='idx')
                    p.text-agenda • {{ agenda.label }}

        .ta-right.my-2(v-if='! waiting')
            Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/checkup/salvar/0/')")

        Panel.mb-2(header='Filtros' :toggleable='true')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-3
                    label.form-label Estabelecimento:
                    Dropdown( v-model='filters.cd_estabelecimento' :options='options.estabelecimentos' :filter='options.estabelecimentos.length > 0'
                        dataKey='id' optionLabel='nm_fantasia' optionValue='id' placeholder='TODOS' @change='applyFilters()' )

                .p-col-12.p-md-3
                    label.form-label Tipo de checkup:
                    Dropdown( v-model='filters.cd_tipo_checkup' :options='options.tipo_checkup' :filter='options.tipo_checkup.length > 0'
                        dataKey='id' optionLabel='ds_checkup' optionValue='id' placeholder='TODOS' @change='applyFilters()' )

                .p-col-12.p-md-3
                    label.form-label Procedimento:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_procedimento' @keyup='checkEmptyField("ds_procedimento")' )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Observações:
                    .p-inputgroup
                        InputText( @keyup.enter.native='applyFilters()' v-model='filters.ds_observacao' @keyup='checkEmptyField("ds_observacao")' )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-3
                    label.form-label Status:
                        Dropdown(
                            v-model='filters.ie_status'
                            :options='options.ieStatus'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            placeholder='TODOS'
                            @change='applyFilters()'
                        )
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='list.length == 0')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            Panel.datatable(header='Lista de checkups')

                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

                DataTable(:value="list")
                    Column(headerStyle='width: 4em' bodyStyle='text-align:center')
                        template(#body='props')
                            ProgressSpinner.waitingStatus(v-if='waitingStatus === props.data.id' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_ativo }" @click='alterarStatus(props.data.id)')
                                i.jam(:class="{ 'jam-check': props.data.ie_ativo, 'jam-minus': !props.data.ie_ativo }")
                    Column(header='Tipo de checkup' field='cd_tipo_checkup.ds_checkup' headerStyle='width: 20%')
                    Column(header='Observações' field='ds_observacao' headerStyle='width: 20%')
                    Column(header='Estabelecimento' field='nm_estabelecimento' headerStyle='width: 20%')
                    Column(header='Preço' bodyStyle='text-align:right' headerStyle='width: 6%')
                        template(#body='props')
                            span {{ $root.formatPrice(props.data.nr_preco) }}
                    Column(header='Desconto' bodyStyle='text-align:right' headerStyle='width: 6%')
                        template(#body='props')
                            span {{ parseFloat(props.data.nr_desconto_medclub) }} %
                    Column(header='Ações' headerStyle='width: 15%')
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mx-1(
                                    v-tooltip.top="'Exibir Checkup Site e App'"
                                    :icon="`jam ${props.data.ie_exibir === true? 'jam-eye':'jam-eye-close'}`"
                                    @click="exibirCheckup(props.data.id)")
                                Button.p-button-raised.p-button-rounded.mx-1(
                                    v-tooltip.top="'Ver agendas'"
                                    icon="jam jam-book"
                                    @click="showAgendas(props.data)")
                                Button.p-button-raised.p-button-rounded.mx-1(
                                    v-tooltip.top="'Ver procedimentos'"
                                    icon="jam jam-task-list"
                                    @click="showProcedimentos(props.data.cd_tipo_checkup.id)")
                                Button.p-button-raised.p-button-rounded.mx-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="$router.push(`/checkup/salvar/${ props.data.id }/`)")
                                Button.p-button-raised.p-button-danger.p-button-rounded.mx-1(
                                    v-tooltip.top="'Remover'"
                                    icon="jam jam-trash"
                                    @click="dialogApagar = true; dialogApagar_data = props.data")

                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
.checkup-listar {
    .dialog-visualizarProcedimentos, .dialog-visualizarAgendas {
        width: 98%;
        max-width: 640px;
        max-height: 480px;
        .waitingProcedimentos, .waitingAgendas {
            text-align: center;
            margin: 4em 0;
            .p-progress-spinner {
                width: 48px;
                height: auto;
            }
        }
        .text-title {
            text-align: center;
            font-weight: 600;
        }
        .text-procedimento, .text-agenda {
            margin: 0;
            font-size: .92em;
        }
    }
    .status-indicator {
        display: inline-block;
        font-size: 0;
        width: 24px;
        height: 24px;
        line-height: 36px;
        border-radius: 50%;
        transition: 250ms;
        cursor: pointer;
        background-color: #94c860;
        border: 1px solid #84b553;
        &:hover { background-color: #84b553; }
        &.alt {
            background-color: #007ad9;
            border: 1px solid #0e6bb3;
            &:hover { background-color: #0e6bb3; }
        }

        &.off {
            background-color: #d33838;
            border: 1px solid #b33030;
            &:hover { background-color: #b33030; }
            &.alt {
                background-color: #bbb;
                border: 1px solid #aaaaaa;
                &:hover { background-color: #aaa; }
            }
        }
        .jam {
            color: #fff;
        }
    }
    .waitingStatus {
        width: 25px;
        height: auto;
    }
}
</style>

<script>
import ProgressBar from 'primevue/progressbar'
import Button from 'primevue/button'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Dialog from 'primevue/dialog'
import Column from 'primevue/column'
import Tooltip from 'primevue/tooltip'
import ProgressSpinner from 'primevue/progressspinner'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'

import wsConfigs from './../../middleware/configs'
import { Agenda, Checkup, Estabelecimento } from '../../middleware'
export default {
    components: { ProgressBar, Button, Panel, Paginator, DataTable, Column,
        Dropdown, Tooltip, Dialog, ProgressSpinner, InputText },
    directives: { tooltip: Tooltip },
    beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'checkup') && vm.$store.state.filters)
					vm.filters = vm.$store.state.filters
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
    created () {
        this.waiting = true
        Checkup.findAllTipoCheckup().then(response => {
            this.options.tipo_checkup = [ { id: null, ds_checkup: 'TODOS' } ]
            if (response.status === 200) this.options.tipo_checkup = this.options.tipo_checkup.concat(response.data)
            Estabelecimento.findAll().then(response => {
                this.options.estabelecimentos = [ { id: null, nm_fantasia: 'TODOS' } ]
                if (response.status === 200) this.options.estabelecimentos = this.options.estabelecimentos.concat(response.data)
                // this.applyFilters()
            })
        })
    },
    data () {
        return {
            list: [],
            waiting: false,
            waitingProcedimentos: false,
            waitingAgendas: false,
            waitingStatus: 0,
            waitingExibir: 0,
            filters: {
                ds_observacao: null,
                cd_estabelecimento: null,
                ds_procedimento: null,
                cd_tipo_checkup: null,
                ie_status: 'true'
            },
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            options: { 
                estabelecimentos: [],
                tipo_checkup: [],
                ieStatus: [
                    { text: "TODOS", value: null },
                    { text: "Ativo", value: 'true' },
                    { text: "Inativo", value: 'false' },
                ]
            },
            dialogApagar: false,
            dialogApagar_data: {},
            dialogVisualizar: false,
            dialogVisualizar_data: {},
            dialogVisualizarAgendas: false,
            dialogVisualizarAgendas_data: {}
        }
    },
    methods: {
        getList (params) {
            this.waiting = true
            return Checkup.findAll(params).then(response => {
                if (response.status == 200) {
                    // this.paginator.count = response.data.count
                    this.list = response.data
                }
                this.waiting = false
                return true
            })
        },
        applyFilters () {
            let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
            if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) params[key] = this.filters[key]
            })
            this.getList(params)
        },
        onPage (ev) {
            this.paginator.page = ev.page + 1
            this.applyFilters()
        },
        checkEmptyField (field) {
            if (! this.filters[field].length) this.applyFilters()
        },
        showProcedimentos (id) {
            this.dialogVisualizar = true
            this.waitingProcedimentos = true
            Checkup.findTipoCheckup(id).then(response => {
                this.waitingProcedimentos = false
                if (response.status === 200) this.dialogVisualizar_data = response.data
            })
        },
        showAgendas (checkup) {
            this.dialogVisualizarAgendas = true
            this.waitingAgendas = true
            Agenda.findByEstabelecimento(checkup.cd_estabelecimento, { ie_tipo_agenda: 'C' }).then(response => {
                this.waitingAgendas = false
                if (response.status === 200) {
                    this.dialogVisualizarAgendas_data = checkup
                    this.dialogVisualizarAgendas_data.agendas = response.data.filter(agenda => checkup.cd_agendas.includes(agenda.id))

                    this.dialogVisualizarAgendas_data.agendas.forEach(item => {
                        item.label = `${ item.cd_especialidades.map(e => e.nm_especialidade).toString() } - ${ item.nm_especialista }`
                    })
                }
            })
        },


        remove () {
            this.dialogApagar = false
            this.waiting = true
            Checkup.remove(this.dialogApagar_data.id).then(response => {
                if (response.status === 200) {
                    this.$toast.info('Checkup removido com sucesso', { duration: 3000 })
                    this.applyFilters()
                }
            })
        },
        alterarStatus (id) {
            let checkup = this.list.find(a => a.id === id)
            this.waitingStatus = id
            Checkup.save({ id, ie_ativo: ! checkup.ie_ativo }).then(response => {
                this.waitingStatus = 0
                if (response.status === 200) {
                    checkup.ie_ativo = ! checkup.ie_ativo
                    if (checkup.ie_ativo)
                        this.$toast.success('Check-up habilitado', { duration: 3000 })
                    else
                        this.$toast.info('Check-up desabilitado', { duration: 3000 })
                } else if (response.status === 400) {
                    this.$toast.error(response.data.detail, { duration: 3000 })
                }
            })
        },
        exibirCheckup (id) {
            let checkup = this.list.find(a => a.id === id)
            this.waitingExibir = id
            Checkup.save({ id, ie_exibir: !checkup.ie_exibir }).then(response => {
                this.waitingExibir = 0
                if (response.status === 200) {
                    checkup.ie_exibir = ! checkup.ie_exibir
                    if (checkup.ie_exibir)
                        this.$toast.success('Check-up Visível.', { duration: 3000 })
                    else
                        this.$toast.info('Check-up não visível.', { duration: 3000 })
                } else if (response.status === 400) {
                    this.$toast.error(response.data.detail, { duration: 3000 })
                }
            })
        },
    }
}
</script>
